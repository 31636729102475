@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.swissknife-font {
  font-family: 'Permanent Marker';
}

body {
  margin: 0;
  padding: 0;
}

